<template>
  <v-text-field
    :label="label"
    :value="value"
    :type="type"
    :append-icon="icon"
    @input="$emit('input', $event)"
    @click:append="show = !show"
  />
</template>

<script>
  export default {
    name: 'PasswordField',
    props: {
      value: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: 'Password Field',
      },
    },
    data () {
      return {
        show: false,
      }
    },
    computed: {
      type () {
        return this.show ? 'text' : 'password'
      },
      icon () {
        return this.show ? 'mdi-eye-off' : 'mdi-eye'
      },
    },
    methods: {

    },
  }
</script>
